/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import { Modal } from "ant-design-vue";
import store from "../store/store";

Vue.prototype.confirm = Modal.confirm;

//解决点击相同菜单报NavigationDuplicated错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: "/",
    component: () => import("@/components/HomeLayout.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "@/views/Home.vue")
      },
      {
        path: "summarize",
        name: "Summarize",
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "@/views/Summarize.vue")
      },
      {
        path: "information",
        name: "Information",
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "@/views/Information.vue")
      },
      // 会员首页
      {
        path: "member-page",
        name: "MemberPage",
        component: () =>
          import(
            /* webpackChunkName: "MemberPage" */ "@/views/member/MemberPage.vue"
          )
      },
      // 会员流程
      {
        path: "member-process",
        name: "MemberProcess",
        component: () =>
          import(
            /* webpackChunkName: "MemberProcess" */ "@/views/member/MemberProcess.vue"
          )
      },
      // 会员公示
      {
        path: "member-public",
        name: "MemberPublic",
        component: () =>
          import(
            /* webpackChunkName: "MemberPublic" */ "@/views/member/MemberPublic.vue"
          )
      },
      // 会员申请
      {
        path: "member-apply",
        name: "MemberApply",
        component: () =>
          import(
            /* webpackChunkName: "MemberApply" */ "@/views/member/MemberApply.vue"
          )
      },
      {
        path: "information-detail",
        name: "InformationDetail",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */ "@/views/InformationDetail.vue"
          )
      },
      {
        path: "game-page",
        name: "GamePage",
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "@/views/GamePage.vue")
      },
      {
        path: "game-page-detail",
        name: "GamePageDetail",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */ "@/views/GamePageDetail.vue"
          )
      },
      {
        path: "public-welfare-class",
        name: "PublicWelfareClass",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */ "@/views/PublicWelfareClass.vue"
          )
      },
      {
        path: "public-welfare-class-list",
        name: "PublicWelfareClassList",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */ "@/views/PublicWelfareClassList.vue"
          )
      },
      {
        path: "public-welfare-class-detail",
        name: "PublicWelfareClassDetail",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */ "@/views/PublicWelfareClassDetail.vue"
          )
      },
      {
        path: "art-appreciation",
        name: "ArtAppreciation",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */ "@/views/ArtAppreciation.vue"
          )
      },
      {
        path: "children-works",
        name: "ChildrenWorks",
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "@/views/ChildrenWorks.vue")
      },
      {
        path: "children-works-detail",
        name: "ChildrenWorksDetail",
        component: () =>
          import(
            /* webpackChunkName: "HomePage" */ "@/views/ChildrenWorksDetail.vue"
          )
      },
      {
        path: "trendy-art",
        name: "TrendyArt",
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "@/views/TrendyArt.vue")
      },
      {
        path: "the-paintings",
        name: "ThePaintings",
        component: () =>
          import(/* webpackChunkName: "HomePage" */ "@/views/ThePaintings.vue")
      }
    ]
  },
  // 智库
  {
    path: "/intelligence-library",
    name: "IntelligenceLibrary",
    component: () =>
      import(
        /* webpackChunkName: "IntelligenceLibrary" */ "@/views/IntelligenceLibrary.vue"
      )
  },
  // 联系
  {
    path: "/contact-page",
    name: "ContactPage",
    component: () =>
      import(/* webpackChunkName: "ContactPage" */ "@/views/ContactPage.vue")
  }
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from) {
    return { x: 0, y: 0 };
  }
});

router.afterEach(() => {
  NProgress.done();
});
export default router;
