import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  // 数据
  state: {
    selectNavItem: {},
  },
  mutations: {
    SET_SELECT_NAV(state, navItem) {
      state.selectNavItem = navItem;
    },
  },
  actions: {
    setSelectNav({ commit }, selectNavItem) {
      commit("SET_SELECT_NAV", selectNavItem);
    },
  },
  plugins: [createPersistedState()]
});
