<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath" />
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      :key="$route.fullPath"
    ></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style>
#app {
  min-height: 100vh;
  min-width: 1200px;
}
</style>
